import { Fragment } from "react";
import { BrowserRouter } from "react-router-dom";

// Common
import { AppComponent, LOADING_STATE, ScrollToTop } from "./common/AppComponent";
import BasicForm from "./common/Form/BasicForm";

// CSS
import "semantic-ui-css/semantic.min.css";
import "./common/Box/Box.css";
import "./util/Global.css";
import "./App.css";


export default class App extends AppComponent {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      // Screen resolution
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    };

    // Set title
    this.setPageTitle("Login - AppInHub");
  }

  success() {
    // Redirect
    document.location.href = "/";
  }

  render() {
    if (this.state.loadingState === LOADING_STATE.LOADED) {
      return (
        <Fragment>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ScrollToTop />
            <div className="aih-page">

              <BasicForm
                className="aih-box"
                header="Login"
                fields={[
                  {
                    type: "input",
                    placeholder: "Username",
                    label: "Username",
                    name: "username",
                  },
                  {
                    type: "input",
                    placeholder: "Password",
                    label: "Password",
                    name: "password",
                    ftype: "password",
                  },
                ]}
                buttonLabel="Login"
                validationMap={{}}
                varInQuery={true}
                sendUrl={`/login`}
                method="POST"
                parentReload={this.success} />

              <footer>
                <span>AppInHub.com - Login</span>
              </footer>

              {/* Error handling */}
              {this.showError()}
            </div>
          </BrowserRouter >
        </Fragment >
      );
    } else {
      return super.render();
    }
  }
}