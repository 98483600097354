import { Component, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Loader, Message } from "semantic-ui-react";

// CSS
import "./AppComponent.css";

// ------------------------------------------
// App Component v1.2.1
// ------------------------------------------


export const LOADING_STATE = {
  LOADING: 1,
  LOADED_WITH_ERROR: 2,
  LOADED: 3,
};

export class AppComponent extends Component {

  // Constructor

  constructor(props, urlFromPath = false) {
    super(props);

    // Get the url
    const url = urlFromPath === true ? window.location.pathname : props.url;

    // Get the loading
    let loadingState;
    if (props.loadingState === LOADING_STATE.LOADED || url === undefined) {
      loadingState = LOADING_STATE.LOADED;
    } else if (props.loadingState === LOADING_STATE.LOADED_WITH_ERROR) {
      loadingState = LOADING_STATE.LOADED_WITH_ERROR;
    } else {
      loadingState = LOADING_STATE.LOADING;
    }

    this.state = {
      loginUri: "/login",
      apiPrefix: "/api",
      url: url,
      loadingState: loadingState,
    };

    this.loadComponentData = this.loadComponentData.bind(this);
  }

  // Component management

  componentDidMount() {
    if (this.state.loadingState === LOADING_STATE.LOADING) {
      this.loadComponentData();
    }
  }

  loadComponentData() {
    this.sendGetRequest(this.state.url);
  }

  // URL

  getSearchParam(queryString, param) {
    const params = new URLSearchParams(queryString);
    return params.get(param);
  }

  // Sending HTTP requests

  sendGetRequest(url, key) {
    this.sendRequest(url, "GET")
      .then((data) => {
        if (data.error) {
          this.setState({
            loadingState: this.state.loadingState === LOADING_STATE.LOADING ? LOADING_STATE.LOADED_WITH_ERROR : this.state.loadingState,
            error: data,
          });
        } else {
          this.processData(data, key);
        }
      });
  }

  sendPutRequest(url, body = undefined) {
    this.sendRequest(url, "PUT", body)
      .then((data) => {
        if (data.error) {
          this.setState({
            error: data,
          });
        } else {
          this.loadComponentData();
        }
      });
  }

  sendDeleteRequest(url) {
    this.sendRequest(url, "DELETE")
      .then((data) => {
        if (data.error) {
          this.setState({
            error: data,
          });
        } else {
          this.loadComponentData();
        }
      });
  }

  async sendRequest(url, method, body = undefined, binaryBody = false) {
    // If not JSON
    if (binaryBody) {
      if (body) {
        return await this._invokeApiRequest(url, method, {
          "Accept": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        }, body);
      } else {
        return { error: "Empty body", message: "Request body is missing.", }
      }
    } else {
      return await this._invokeApiRequest(url, method, {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      }, body ? JSON.stringify(body) : undefined);
    }
  }

  async _invokeApiRequest(url, method, headers, body = undefined) {
    if (url && method && headers) {
      return await fetch(this.state.apiPrefix + url, {
        method: method,
        headers: headers,
        body: body,
      })
        .then((response) => {
          // If unauthorized / session expired, redirect to login page
          if (response.status === 401) {
            document.location.href = this.state.loginUri;
          } else {
            // Get the content type of response
            const contentType = response.headers.get("Content-Type");
            // If the content type is JSON
            if (contentType && contentType.indexOf("application/json") !== -1) {
              try {
                return response.json();
              } catch (err) {
                return { error: "Invalid response", message: "The server response is invalid.", };
              }
            } else {
              return { error: "Invalid response", message: "The server response is invalid.", };
            }
          }
        })
        .then((response) => {
          // If response contains error
          if (response && response.error) {
            let message = "";
            if (response.errors && Array.isArray(response.errors)) {
              // If error contains error array, show it
              // TODO: Show message based on the error code
              message = (
                <Message.List>
                  {response.errors.map((err, index) => (
                    <Message.Item key={index}>
                      {err.field ? this.capitalizeFirstLetter(err.field.split(/(?=[A-Z])/).join(" ")) + " " : ""}
                      {err.defaultMessage}
                    </Message.Item>
                  ))}
                </Message.List>
              );
            } else {
              // Otherwise show just message
              message = response.message;
            }
            // Return object with error
            return { error: response.error, message: message, };
          } else {
            return response;
          }
        }, (error) => {
          return { error: "An error occurred", message: error.message, };
        });
    } else {
      return { error: "Incomplete information", message: "Missing URL, method, or header.", }
    }
  }

  // Processing HTTP response

  processData(data, key) {
    this.setState({
      loadingState: LOADING_STATE.LOADED,
      data: data,
    });
  }

  // Content rendering

  render() {
    const { loadingState, error, } = this.state;
    if (loadingState === LOADING_STATE.LOADED_WITH_ERROR) {
      return <div className="aih-error-area">{error.message}</div>;
    } else if (loadingState === LOADING_STATE.LOADING) {
      return this.showLoader();
    } else {
      return <div className="aih-error-area">An unknown error has occurred.</div>;
    }
  }

  showLoader() {
    return <Loader inline="centered" active />;
  }

  showError() {
    if (this.state.error && this.state.error.message) {
      setTimeout(() => {
        this.setState({
          error: undefined,
        });
      }, 6000);

      return (
        <div className="aih-component-error">
          {this.state.error.message}
        </div>
      );
    } else {
      return "";
    }
  }

  // Processing page

  setPageTitle(pageTitle) {
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
  }

  // Form validation

  validate(object, validationMap) {
    // TODO: Validation of an object in an object
    let errorMap = [];
    // If not undefined
    if (object && validationMap) {
      // For each key in object
      Object.keys(object).forEach(key => {
        // If exists in validation map
        if (validationMap[key]) {
          // Verify string using regex (remove accents)
          const regex = new RegExp(validationMap[key].regex);
          const value = object[key].normalize("NFD").replace(/\p{Diacritic}/gu, "")
          if (!regex.test(value)) {
            // Get the name
            const name = validationMap[key].name ? validationMap[key].name : key;
            // Push to the array
            errorMap.push({
              name: name,
              message: "is not valid.",
            });
          }
        }
      });
    }
    return errorMap;
  }

  // Processing text

  getNonNullValue(value, def = "") {
    return value && value !== null ? value : def;
  }

  getWithCurrencySign(value, currency) {
    if (value) {
      if (currency === "USD") {
        return "$" + value;
      } else if (currency === "EUR") {
        return "?" + value;
      } else {
        return "";
      }
    }
  };


  capitalizeFirstLetter(text) {
    if (text && text !== null) {
      return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
    } else {
      return "";
    }
  }

  // Processing number

  formatNumber(number, def = "", inPercent = false) {
    if (number !== undefined && number !== null) {
      return new Intl.NumberFormat().format(number) + (inPercent ? "%" : "");
    } else {
      return def;
    }
  }

  // Processing date

  formatDateTime(date) {
    if (date && date !== null) {
      const dateToFormat = new Date(date);
      return dateToFormat.toUTCString();
    } else {
      return "None";
    }
  }

  formatDate(date) {
    if (date && date !== null) {
      const dateToFormat = new Date(date);
      return dateToFormat.toDateString();
    } else {
      return "None";
    }
  }

  // Sorting

  sort(values, previousIndex, selectedIndex, direction) {
    // If different column
    if (previousIndex !== selectedIndex) {
      // Sort
      const sortedValues = values.sort(function (a, b) {
        if (typeof a[selectedIndex].value === "number") {
          return b[selectedIndex].value - a[selectedIndex].value;
        } else {
          if (a[selectedIndex].value < b[selectedIndex].value) {
            return -1;
          } else if (a[selectedIndex].value > b[selectedIndex].value) {
            return 1;
          } else {
            return 0;
          }
        }
      });

      return {
        values: sortedValues,
        columnIndex: selectedIndex,
        direction: "ascending",
      };
    } else {
      return {
        values: values.reverse(),
        columnIndex: selectedIndex,
        direction: direction === "ascending" ? "descending" : "ascending",
      };
    }
  }

}

export function ScrollToTop() {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      // Scroll to hash
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return null;
}
