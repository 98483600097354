import { Header, Form, Message } from "semantic-ui-react";

// Common
import { AppComponent, LOADING_STATE } from "../AppComponent";

// CSS
import "./BasicForm.css";

// ------------------------------------------
// Basic Form v1.0.1
// ------------------------------------------


export default class BasicForm extends AppComponent {

  constructor(props) {
    super(props);

    // Prepare the default values and check file form
    let values = [], fileForm = false;
    this.props.fields.forEach((field, index) => {
      // Set default values
      if (field.defaultValue !== undefined) {
        values.push(field.defaultValue);
      } else {
        values.push("");
      }

      // Check field type
      if (field.ftype === "file") {
        fileForm = true;
      }
    })

    this.state = {
      ...this.state,
      message: "",
      values: values,
      fileForm: fileForm,
      reset: props.reset ? true : false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, { name, value, checked }) {
    const { values } = this.state;

    // Get the index
    const index = name.replace("field_", "");

    // Set the value
    if (this.props.fields[index].ftype === "file") {
      values[index] = event.target.files[0];
    } else if (checked) {
      values[index] = checked;
    } else {
      values[index] = value;
    }

    // Set state
    this.setState({ values: values });
  }

  getErrorFormMessage(errorMap) {
    return (
      <Message negative>
        <Message.Header>Invalid values</Message.Header>
        <Message.List>
          {errorMap.map((error, index) => (
            <Message.Item key={index}>{this.capitalizeFirstLetter(error.name.split(/(?=[A-Z])/).join(" ") + " " + error.message)}</Message.Item>
          ))}
        </Message.List>
      </Message>
    )
  }

  send() {
    const { values, fileForm } = this.state;

    // Build body
    let body = this.props.fields.reduce((obj, { name }, index) => {
      const keys = name.split("."); // Also nested fields...
      let current = obj;

      keys.forEach((key, i) => {
        if (i === keys.length - 1) {
          current[key] = values[index];
        } else {
          current[key] = current[key] || {};
          current = current[key];
        }
      });

      return obj;
    }, {});

    // Validate
    const errorMap = this.validate(body, this.props.validationMap);

    // Check error map
    if (Array.isArray(errorMap) && errorMap.length > 0) {
      this.setState({ message: this.getErrorFormMessage(errorMap) });
    } else {
      let binaryBody = false, searchQuery = "";

      // If the variable should be as the url search query
      if (this.props.varInQuery) {
        searchQuery += "?";

        // Add all variables
        Object.keys(body).forEach(key => {
          searchQuery += key + "=" + body[key] + "&";
        });

        // Remove the last char
        searchQuery = searchQuery.slice(0, -1);

        // Set body to undefined
        body = undefined;
      } else if (fileForm) { // If file form, use form data
        binaryBody = true;
        body = new FormData();

        // Append form data
        this.props.fields.forEach((field, index) => {
          if (values[index] && values[index] !== "") {
            body.append(field.name, values[index]);
          }
        });
      }

      // Send a request
      this.sendRequest(this.props.sendUrl + searchQuery, this.props.method, body, binaryBody)
        .then((data) => {
          if (data.error) {
            this.setState({
              message: <Message negative icon="exclamation" header={data.error} content={data.message} />
            });
          } else {
            this.success();
          }
        });
    }
  }

  success() {
    // Prepare the default values if set
    if (this.state.reset) {
      let values = [];
      this.props.fields.forEach((field, index) => {
        if (field.defaultValue) {
          values.push(field.defaultValue);
        } else {
          values.push("");
        }
      })

      // Clean form
      this.setState({
        values: values,
      });
    }

    // Clean message
    this.setState({
      message: "",
    });

    // Reload the parent component
    if (this.props.parentReload) {
      this.props.parentReload();
    }
  }

  render() {
    if (this.state.loadingState === LOADING_STATE.LOADED) {
      const { message, values } = this.state;

      // Prepare the form fields
      let groups = [], group = [];
      for (let i = 0; i < this.props.fields.length; i++) {
        // Get the field
        const field = this.props.fields[i];
        const formField = (
          field.type === "input" ? (
            <Form.Input
              key={i}
              fluid
              placeholder={field.placeholder}
              label={field.label}
              name={"field_" + i}
              type={field.ftype}
              value={field.ftype === "file" ? undefined : values[i]}
              disabled={field.disabled}
              onChange={this.handleChange} />
          ) : field.type === "textarea" ? (
            <Form.TextArea
              key={i}
              rows={10}
              placeholder={field.placeholder}
              label={field.label}
              name={"field_" + i}
              value={values[i]}
              onChange={this.handleChange} />
          ) : field.type === "dropdown" ? (
            <Form.Dropdown
              key={i}
              clearable
              fluid
              search
              selection
              placeholder={field.placeholder}
              label={field.label}
              name={"field_" + i}
              options={field.options}
              value={values[i]}
              disabled={field.disabled}
              onChange={this.handleChange} />
          ) : field.type === "checkbox" ? (
            <Form.Checkbox
              key={i}
              label={field.label}
              name={"field_" + i}
              checked={values[i]}
              disabled={field.disabled}
              onChange={this.handleChange} />
          ) : null
        );

        // Push to the group
        group.push(formField);

        // Create a new group
        if (!(field.newLine || field.newLine === false) || i + 1 >= this.props.fields.length) {
          groups.push(group);
          group = [];
        }
      }

      return (
        <div className={this.props.className}>

          <Header as="h3" dividing>
            {this.props.header}
          </Header>

          <Form className="aih-basic-form">
            {groups?.map((group, groupIndex) => (
              <Form.Group key={groupIndex} widths="equal">
                {group?.map((field, fieldIndex) => (
                  field
                ))}
              </Form.Group>
            ))}

            {this.props.buttonLabel ? (
              <Form.Button
                type="submit"
                content={this.props.buttonLabel}
                onClick={() => this.send()} />
            ) : ""}
          </Form>

          {message}
        </div>
      );
    } else {
      return super.render();
    }
  };

}
